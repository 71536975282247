import * as React from 'react';
import { Field, FormikValues, useFormikContext } from 'formik';
import { Label } from 'styleguide/components/forms';
import { Address } from 'bundles/App/pages/Account/Addresses/types';
import Input from 'styleguide/components/Formik/Input/Input';
import Checkbox from 'styleguide/components/Formik/Checkbox/Checkbox';
import FieldWrapper from 'styleguide/components/Formik/FieldWrapper/FieldWrapper';
import RadioTabs from 'styleguide/components/Formik/RadioTabs/index';
import RadioTab from 'styleguide/components/Formik/RadioTabs/RadioTab/index';
import UserContext from 'contexts/UserContextContainer/UserContext';
import Button from 'styleguide/components/Button/Button';
import AddressBook from 'app/styleguide/components/Formik/AddressBook/AddressBook';
import ValidateAddressContext from 'contexts/ValidateAddressContext/ValidateAddressContext';
import OrderContext from 'contexts/OrderContext/OrderContext';

interface Props {
  addresses: Address[];
  newAddress: boolean;
  setNewAddress: (newAddress: boolean) => void;
}

const ShippingAddressStepEdit = ({ addresses, newAddress, setNewAddress }: Props) => {
  const formikProps = useFormikContext<FormikValues>();
  const { currentUser } = React.useContext(UserContext);
  const { order } = React.useContext(OrderContext);
  const validateAddressContext = React.useContext(ValidateAddressContext);

  return (
    <>
      <div>
        <div className="paragraph-bold-mobile mb-4">Contact Information</div>
        <Field
          required
          type="email"
          name="email"
          inPlaceError
          component={Input}
          label="Email address"
          data-cy="subscribeMailEmailInput"
        />
        {currentUser && currentUser.klaviyoSubscribed ? (
          <div />
        ) : (
          <div className="flex items-center justify-start">
            <Field name="subscribeMail" component={Checkbox} size="sm" data-cy="subscribeMailCheckbox" />
            <Label
              placement="left"
              normalFontWeight
              className="mt-1 !pl-2 !font-hvRoman !text-sm !leading-5 text-gray-500 -md:!pl-4"
            >
              Sure, occasionally send me an email about Printivity tips and deals.
            </Label>
          </div>
        )}
      </div>
      {!!currentUser && !currentUser.smsEnabled && (
        <div className="!mt-8 flex flex-col">
          <div className="paragraph-bold-mobile">Mobile Notifications</div>
          <div className="caption mb-6 mt-1 text-gray-500">
            Avoid delaying your order by receiving text reminders to your mobile device for time-sensitive
            deadlines.
          </div>
          <div className="flex -md:flex-col">
            <FieldWrapper
              className="mr-8 w-full md:flex-[60%]"
              Input={
                // TODO: get this working with boolean values
                <RadioTabs>
                  <Field
                    name="sendSmsNotifications"
                    dataCy="sendSmsNotificationsRadioBtn.true"
                    checked={formikProps.values.sendSmsNotifications === '1'}
                    value="1"
                    label="Yes"
                    component={RadioTab}
                    size="sm"
                  />
                  <Field
                    name="sendSmsNotifications"
                    dataCy="sendSmsNotificationsRadioBtn.false"
                    checked={formikProps.values.sendSmsNotifications === '0'}
                    value="0"
                    label="No"
                    component={RadioTab}
                    size="sm"
                  />
                </RadioTabs>
              }
            />
            <Field
              name="smsPhone"
              data-cy="smsPhoneInput"
              component={Input}
              label="Mobile Number"
              secondaryMessage="Printivity does not use your phone number for marketing"
              wrapperClassName="-md:mb-2"
            />
          </div>
        </div>
      )}
      <div className="mt-9">
        <AddressBook
          addressType="ship"
          address={order.shippingAddress}
          addresses={addresses}
          newAddress={newAddress}
          setNewAddress={setNewAddress}
          titleOnTheLeft
          addNewAddressToBookCheckBox={!!currentUser}
          addressFormProps={{
            zipWarning: true,
            poBoxWarning: true,
            checkoutMode: true,
            className: '!mt-0 mb-5',
            international: true,
            validCountries: ['US', 'CA'],
          }}
        />
      </div>
      <Button
        className="w-full !px-0 !font-hvMedium !text-base"
        type="button"
        color="orange"
        onClick={() => {
          if (formikProps.values.shipAddressAttributes.countryId === 214) {
            validateAddressContext.validateAddress(null, formikProps.values.shipAddressAttributes);
          } else {
            formikProps.submitForm();
          }
        }}
        data-cy="shipAddressStepBtn"
        disabled={!formikProps.values.shipAddressId && (!formikProps.dirty || !formikProps.isValid)}
      >
        Select Delivery
      </Button>
    </>
  );
};
export default ShippingAddressStepEdit;
