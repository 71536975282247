import * as React from 'react';
import cn from 'classnames';
import { Field, FormikValues, useFormikContext } from 'formik';
import { formatMoney } from 'utils/money';
import StepWrapper from '../StepWrapper/StepWrapper';
import Checkbox from 'styleguide/components/Formik/Checkbox/Checkbox';
import { Label, Checkbox as TariffCheckbox } from 'styleguide/components/forms';
import Textarea from 'styleguide/components/Formik/Textarea/Textarea';
import Image from 'styleguide/components/Image/Image';
import Button from 'styleguide/components/Button/Button';
import InPlaceMessage from 'styleguide/components/InPlaceMessage/InPlaceMessage';
import { LineItem } from 'api/orders/types';
import Collapse from 'styleguide/components/Collapse/Collapse';
import Span from 'styleguide/components/Span/Span';
import { checkout } from 'api/gtm';
import Captcha from 'bundles/App/pages/ContactUsPage/Captcha';
import AppContext from 'contexts/AppContext/AppContext';
import OrderContext from 'contexts/OrderContext/OrderContext';
import { CheckoutStep, StepsContext } from '../StepsContext/StepsContext';

const NUMBER_OF_SHOWN_ITEMS = 3;

const LineItemRow = ({ item }) => {
  const appContext = React.useContext(AppContext);
  const quantity: number = parseInt(item.quoterJson.documentCount, 10);
  const pages: number = parseInt(item.quoterJson.documentPageCount, 10);
  const name: string = item.quoterJson.name !== '' ? item.quoterJson.name : null;
  const getProductImage = (productName: string) => {
    const product = appContext.store.products.find(prod => prod.name === productName);
    return product ? product.images.master[0].url.product : '';
  };

  return (
    <div className="mb-6 flex justify-between">
      <div className="flex">
        <Image
          alt={item.product.name}
          url={getProductImage(item.product.name)}
          className="h-18 mr-2 w-14 object-contain lg:h-20 lg:w-16"
        />
        <div className="flex flex-col justify-center">
          <p className="sub-heading-bold !m-0 line-clamp-2 text-lg lg:text-xl">{item.product.name}</p>
          {pages > 0 ? (
            <p className="paragraph-medium-mobile !m-0 line-clamp-1 text-xs text-gray-500 lg:text-sm">{`${quantity} qty x ${pages} pages`}</p>
          ) : (
            <p className="paragraph-medium-mobile !m-0 line-clamp-1 text-gray-500">{`${quantity} qty`}</p>
          )}
          {!!name && <p className="paragraph-medium-mobile !m-0 line-clamp-2 text-gray-500">{name}</p>}
        </div>
      </div>
      <div className="paragraph-mobile">{formatMoney(item.total)}</div>
    </div>
  );
};

const ItemRow = ({ title, amount, bold = false, valueDataCy }) => (
  <div className="flex content-center justify-between py-3">
    <p
      className={cn(
        '!m-0',
        bold ? 'paragraph-bold-desktop text-default' : 'paragraph-medium-mobile text-gray-500 ',
      )}
    >
      {title}
    </p>
    <p
      data-cy={valueDataCy}
      className={cn('!m-0', bold ? 'paragraph-bold-desktop text-default' : 'paragraph-mobile text-gray-500')}
    >
      {amount}
    </p>
  </div>
);

interface Props {
  recaptchaRef: React.RefObject<unknown>;
  setRecaptchaResponse: (response: string) => void;
}

const ReviewOrderStep = ({ ...props }: Props) => {
  const { order } = React.useContext(OrderContext);
  const formikProps = useFormikContext<FormikValues>();
  const [summaryExpanded, setSummaryExpanded] = React.useState<boolean>(false);
  const [tariffTerms, setTariffTerms] = React.useState(order.shipment?.address?.countryId === 214);
  const { currentStep } = React.useContext(StepsContext);
  React.useEffect(() => {
    if (order.shipment?.address?.countryId === 214) {
      setTariffTerms(true);
    }
  }, [order.shipment?.address?.countryId]);
  return (
    <StepWrapper step={CheckoutStep.Confirm} title="Review Order">
      {currentStep === CheckoutStep.Confirm && (
        <div>
          <div className="rounded-lg border border-solid border-neutral-200 bg-shades-0 px-6 py-4">
            <div className="flex flex-col border-b border-solid border-gray-50 py-6">
              {order.lineItems &&
                order.lineItems
                  .slice(0, NUMBER_OF_SHOWN_ITEMS)
                  .map(item => <LineItemRow item={item} key={item.id} />)}
              {order.lineItems && order.lineItems.length > NUMBER_OF_SHOWN_ITEMS && (
                <Collapse>
                  <Collapse.Item
                    title={
                      <div className="paragraph-medium-mobile mr-2 text-gray-500">
                        {summaryExpanded
                          ? 'Close'
                          : `See Remaining ${order.lineItems.length - NUMBER_OF_SHOWN_ITEMS}  Items`}
                      </div>
                    }
                    classNameBody="!bg-shades-0 md:!px-0 overflow-hidden"
                    onToggle={() => setSummaryExpanded(!summaryExpanded)}
                    fixedIcon={false}
                    isWide
                    withPadding
                    showMore
                    centeredTitle
                    iconClassName="!h-2 !w-2 flex"
                    iconColor="darkGray"
                    body={
                      order.lineItems &&
                      order.lineItems
                        .slice(NUMBER_OF_SHOWN_ITEMS)
                        .map(item => <LineItemRow item={item} key={item.id} />)
                    }
                  />
                </Collapse>
              )}
            </div>
            <div className="mt-3">
              <ItemRow
                valueDataCy="reviewOrder.summarySubtotal"
                title="Subtotal"
                amount={formatMoney(order.subtotal)}
              />
            </div>
            <ItemRow
              valueDataCy="reviewOrder.summaryShipping"
              title="Shipping &amp; Handling"
              amount={formatMoney(order.shippingTotal)}
            />
            {order.promotionTotal && parseFloat(order.promotionTotal) !== 0 && (
              <ItemRow
                valueDataCy="reviewOrder.summaryDiscount"
                title="Discount"
                amount={formatMoney(order.promotionTotal)}
              />
            )}
            <ItemRow
              valueDataCy="reviewOrder.summaryTaxes"
              title="Tax"
              amount={formatMoney(order.taxTotal)}
            />
            <ItemRow
              valueDataCy="reviewOrder.summaryTotal"
              title={order.totalAvailableStoreCredit > 0 ? 'Total (Before Credits)' : 'Total'}
              amount={formatMoney(order.total)}
              bold
            />
            {order.totalAvailableStoreCredit > 0 && (
              <>
                <ItemRow
                  valueDataCy="reviewOrder.appliedCredits"
                  title="Applied Credit"
                  amount={formatMoney(-1 * order.totalApplicableStoreCredit)}
                />
                <ItemRow
                  valueDataCy="reviewOrder.totalRemainingAfterCredits"
                  title="Total Remaining"
                  amount={formatMoney(Math.max(order.total - order.totalApplicableStoreCredit, 0.0))}
                  bold
                />
              </>
            )}
          </div>
          <div className="mt-6 border-t border-gray-50 pt-6">
            {order.shipment.address.countryId !== 214 && (
              <div className="flex flex-col">
                <div className="flex items-start">
                  <TariffCheckbox
                    size="sm"
                    id="intlTariffs"
                    // @ts-ignore
                    onClick={e => setTariffTerms(e.target.checked)}
                    checked={tariffTerms}
                  />
                  <Label
                    placement="left"
                    normalFontWeight
                    className="!ml-4 !font-hvRoman !text-sm !leading-5 text-gray-500"
                  >
                    Please note: International orders may be subject to customs duties, taxes, and tariffs
                    imposed by the destination country. These charges are not included in the shipping fee and
                    will be collected separately by the carrier upon delivery. Customers are responsible for
                    any additional fees incurred. We recommend checking with your local customs office for
                    more information before placing your order.
                  </Label>
                </div>
                <InPlaceMessage messageType="error" message="Tariff terms required" />
              </div>
            )}
            <div className="flex flex-col">
              <div className="flex items-start">
                <Field data-cy="orderTermsCheckbox" size="sm" name="orderTerms" component={Checkbox} />
                <Label
                  placement="left"
                  normalFontWeight
                  className="!ml-4 !inline !font-hvRoman !text-sm !leading-5 text-gray-500"
                >
                  By clicking &quot;Pay&quot;, I agree to Printivity&apos;s{' '}
                  <Span className="underline">Terms and Conditions</Span>, which specifies the policies on
                  quality guarantees, file check process, copyrighted material, etc.
                </Label>
              </div>
              <InPlaceMessage
                touched={formikProps.touched.orderTerms as boolean}
                messageType="error"
                message={formikProps.errors.orderTerms as string}
              />
            </div>
            <div className="flex flex-col">
              <div className="flex items-start">
                <Field data-cy="designTermsCheckbox" size="sm" name="designTerms" component={Checkbox} />
                <Label
                  placement="left"
                  normalFontWeight
                  className="!ml-4 !font-hvRoman !text-sm !leading-5 text-gray-500"
                >
                  I understand that delivery will be postponed if my files are not designed correctly.
                  Printivity cannot start production until all my designs are print-ready. If there are any
                  issues with my file, I will be notified promptly (by phone and/or email) after placing my
                  order.
                </Label>
              </div>
              <InPlaceMessage
                touched={formikProps.touched.designTerms as boolean}
                messageType="error"
                message={formikProps.errors.designTerms as string}
              />
            </div>
          </div>
          <div className="mb-6">
            <Field name="specialInstructions" rows={5} component={Textarea} label="Special Instructions" />
          </div>
          <Captcha
            hidden={!order.isRisky}
            reference={props.recaptchaRef}
            verifyCallback={response => props.setRecaptchaResponse(response)}
          />
          <Button
            dataCy="reviewStepBtn"
            className="w-full !px-0 !font-hvMedium !text-base"
            type="button"
            color="orange"
            onClick={() =>
              checkout(
                {
                  step: order.state,
                  currency: 'USD',
                  value: order.subtotal,
                  tax: order.taxTotal,
                  shipping: order.shippingTotal,
                  discount: order.promotionTotal,
                  transaction_id: order.number,
                },
                order.lineItems.map((elem: LineItem) => ({
                  item_name: elem.product.name,
                  item_id: elem.product.slug,
                  price: parseFloat(elem.total) / elem.quantity,
                  quantity: elem.quantity,
                })),
              ).then(() => {
                formikProps.submitForm();
              })
            }
            disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting || !tariffTerms}
          >
            {order.orderTotalAfterStoreCredit > 0
              ? `Pay ${formatMoney(order.orderTotalAfterStoreCredit)}`
              : 'Place Order'}
          </Button>
        </div>
      )}
    </StepWrapper>
  );
};

export default ReviewOrderStep;
