import * as React from 'react';
import clone from 'lodash-es/clone';
import omit from 'lodash-es/omit';
import { boolean, object, number, string } from 'yup';
import { Form, Formik, FormikErrors } from 'formik';
import Grid from 'styleguide/components/Grid/Grid';
import { checkoutOrder } from 'api/deliveryPayment';
import OrderContext from 'contexts/OrderContext/OrderContext';
import { Status } from 'libs/utils/api/types';
import { Order } from 'api/orders/types';
import AppContext from 'contexts/AppContext/AppContext';
import ShippingAddressStep from './ShippingAddressStep/ShippingAddressStep';
import DeliveryStep from './DeliveryStep/DeliveryStep';
import PaymentStep from './PaymentStep/PaymentStep';
import ReviewOrderStep from './ReviewOrderStep/ReviewOrderStep';
import { Address, emptyAddress, emptyCreditCard } from 'bundles/App/pages/Account/Addresses/types';
import { purchase } from 'api/gtm';
import UserContext from 'contexts/UserContextContainer/UserContext';
import { addressSchema, creditCardSchema, phoneRegex } from 'utils/schema';
import { formikStatus, updateFormikStatus } from 'libs/utils/updateFormik';
import { applyPromoCode, removePromoCode, setOrder, updateRiskiness } from 'contexts/OrderContext/actions';
import { getCreditCardProcessorByNumber } from 'utils/cc';
import PurchaseSummary from 'bundles/App/pages/CartPage/MiddleSection/PurchaseSummary/PurchaseSummary';
import NeedHelpSection from 'styleguide/components/NeedHelpSection/NeedHelpSection';
import { CheckoutStep, StepsContext } from './StepsContext/StepsContext';
import { useDefaultWalletId } from 'app/styleguide/components/Formik/ExistingCreditCardForm/ExistingCreditCardForm';
import { PaymentStepFormType } from './PaymentStep/PaymentStepEdit';

interface Props {
  onCreateOrder: (order: Order) => void;
}

const shippingAddressFormSchema = object().shape({
  email: string().email().required('Email is required'),
  subscribeMail: boolean(),
  smsPhone: string().when('sendSmsNotifications', {
    is: value => value === '1',
    then: schema => schema.required('Field is required').matches(phoneRegex, 'Phone number is not valid'),
    otherwise: schema => schema,
  }),
  sendSmsNotifications: string(),
  shipAddressId: number().nullable(),
  shipAddressAttributes: addressSchema,
  saveNewShippingAddress: boolean(),
});

const paymentStepSchemaCreditCard = object().shape({
  newPayment: boolean(),
  useExistingCard: boolean().required(),
  creditCardAttributes: creditCardSchema.when(
    ['newPayment', 'useExistingCard'],
    ([newPayment, useExistingCard], schema) =>
      newPayment && !useExistingCard ? schema.required() : object(),
  ),
  walletPaymentSourceId: number()
    .nullable()
    .when(['newPayment', 'useExistingCard'], ([newPayment, useExistingCard], schema) =>
      newPayment && useExistingCard ? schema.required() : schema.nullable(),
    ),
  useBillingAsShipping: boolean(),
  billAddressId: number().nullable(),
  billAddressAttributes: object().when(
    ['billAddressId', 'useBillingAsShipping'],
    ([billAddressId, useBillingAsShipping]) =>
      !useBillingAsShipping && (billAddressId === 0 || billAddressId === null) ? addressSchema : object(),
  ),
  saveNewBillingAddress: boolean(),
});

const paymentStepSchemaStoreCredit = object().shape({
  useBillingAsShipping: boolean(),
  billAddressId: number().nullable(),
  billAddressAttributes: object().when(
    ['billAddressId', 'useBillingAsShipping'],
    ([billAddressId, useBillingAsShipping]) =>
      !useBillingAsShipping && (billAddressId === 0 || billAddressId === null) ? addressSchema : object(),
  ),
  saveNewBillingAddress: boolean(),
});

const reviewOrderFormSchema = object().shape({
  orderTerms: boolean().oneOf([true], 'You must agree to the terms and conditions'),
  designTerms: boolean().oneOf([true], 'You must agree to the terms and conditions'),
});

const Steps = ({ onCreateOrder }: Props) => {
  const [recaptchaResponse, setRecaptchaResponse] = React.useState<string>(null);
  const recaptchaRef = React.useRef();
  const [showPromo, setShowPromo] = React.useState(false);
  const [appliedCode, setAppliedCode] = React.useState('');
  const { order, dispatch } = React.useContext(OrderContext);
  const appContext = React.useContext(AppContext);
  const { currentUser } = React.useContext(UserContext);
  const { shipment, billingAddress, shippingAddress } = order;
  const { currentStep, orderState, handleNextStep } = React.useContext(StepsContext);
  const defaultWalletId = useDefaultWalletId();

  const [newShipAddress, setNewShipAddress] = React.useState(!shippingAddress);
  const [newBillAddress, setNewBillAddress] = React.useState(!billingAddress);
  const buildAddresses = () => {
    const newAddresses = currentUser ? [...currentUser.addresses] : [];
    // if address is not pickup or already in address book, add it to address list
    if (shippingAddress && newAddresses.every(adr => adr.id !== shippingAddress.id)) {
      newAddresses.push(shippingAddress);
    }

    if (billingAddress && newAddresses.every(adr => adr.id !== billingAddress.id)) {
      newAddresses.push(billingAddress);
    }

    return newAddresses;
  };

  const [addresses, setAddresses] = React.useState<Address[]>([]);

  React.useEffect(() => {
    setAddresses(buildAddresses());
  }, [shippingAddress, billingAddress]);

  const showPromoCodeField = () => {
    setShowPromo(true);
  };

  const buildInitialValues = () => {
    const billAddress = billingAddress
      ? omit(billingAddress, 'id', 'description', 'state', 'country')
      : clone(emptyAddress);

    const shipAddress = shippingAddress
      ? omit(shippingAddress, 'id', 'description', 'state', 'country')
      : clone(emptyAddress);

    const billAddressId = order.billingAddress?.id;
    const shipAddressId = order.shippingAddress?.id;

    return {
      email: currentUser ? currentUser.email : order.email,
      smsPhone: '',
      subscribeMail: currentUser ? !currentUser.klaviyoSubscribed : true,
      sendSmsNotifications: '0',
      orderTerms: false,
      designTerms: false,
      specialInstructions: '',
      billAddressId,
      shipAddressId,
      shipAddressAttributes: shipAddress,
      billAddressAttributes: billAddress,
      saveNewShippingAddress: false,
      saveNewBillingAddress: false,
      useBillingAsShipping: false,
      newPayment: order.payments.filter(p => p.state === 'checkout').length === 0,
      useExistingCard: !!currentUser && currentUser.walletPaymentSources.length > 0,
      walletPaymentSourceId: defaultWalletId || '',
      creditCardAttributes: { ...emptyCreditCard, saveCreditCard: false },
    };
  };

  const getShipAddressId = (values: ReturnType<typeof buildInitialValues>) => {
    const shipAddressId = newShipAddress ? 0 : values.shipAddressId;
    return currentUser ? shipAddressId : 0;
  };

  const createAddressObject = (values: ReturnType<typeof buildInitialValues>) => ({
    order: {
      email: values.email,
      shipAddressId: getShipAddressId(values),
      shipAddressAttributes: values.shipAddressAttributes,
    },
    smsPhone: values.smsPhone,
    sendSmsNotifications: values.sendSmsNotifications,
    subscribeMail: values.subscribeMail,
    saveNewShippingAddress: values.saveNewShippingAddress,
  });

  const getBillAddressId = (values: ReturnType<typeof buildInitialValues>) => {
    let billAddressId = newBillAddress ? 0 : values.billAddressId;
    billAddressId = currentUser ? billAddressId : 0;
    return values.useBillingAsShipping ? order.shippingAddress.id : billAddressId;
  };

  const createPaymentObject = (values: ReturnType<typeof buildInitialValues>) => {
    type ValuesType = ReturnType<typeof buildInitialValues>;
    const paymentObject: {
      order: {
        billAddressId: ValuesType['billAddressId'];
        billAddressAttributes: ValuesType['billAddressAttributes'];
        walletPaymentSourceId?: ValuesType['walletPaymentSourceId'];
        paymentsAttributes?: Array<{ paymentMethodId: string }>;
      };
      useExistingCard: ValuesType['useExistingCard'];
      saveNewBillingAddress: ValuesType['saveNewBillingAddress'];
      paymentSource?: Record<
        number,
        typeof emptyCreditCard & { ccType: ReturnType<typeof getCreditCardProcessorByNumber> }
      >;
    } = {
      order: {
        billAddressId: getBillAddressId(values),
        billAddressAttributes: values.billAddressAttributes,
      },
      useExistingCard: values.useExistingCard,
      saveNewBillingAddress: values.saveNewBillingAddress,
    };

    if (values.newPayment && values.useExistingCard && order.orderTotalAfterStoreCredit > 0) {
      paymentObject.order.walletPaymentSourceId = values.walletPaymentSourceId;
    }
    if (values.newPayment && !values.useExistingCard && order.orderTotalAfterStoreCredit > 0) {
      paymentObject.order.paymentsAttributes = [
        {
          paymentMethodId: appContext.store.paymentMethods[0].id.toString(),
        },
      ];
      const creditCardAttributes = { ...values.creditCardAttributes };
      delete creditCardAttributes.saveCreditCard;
      paymentObject.paymentSource = {
        [appContext.store.paymentMethods[0].id]: {
          ...creditCardAttributes,
          ccType: getCreditCardProcessorByNumber(values.creditCardAttributes.number),
        },
      };
    }

    return paymentObject;
  };

  const createConfirmationObject = (values: ReturnType<typeof buildInitialValues>) => ({
    order: {
      specialInstructions: values.specialInstructions,
    },
    saveCreditCard: values.newPayment && values.creditCardAttributes.saveCreditCard,
    recaptchaResponse,
  });

  const createOrderObject = (values: ReturnType<typeof buildInitialValues>) => {
    if (currentStep === CheckoutStep.Address) {
      return createAddressObject(values);
    }
    if (currentStep === CheckoutStep.Payment) {
      return createPaymentObject(values);
    }
    if (currentStep === CheckoutStep.Confirm) {
      return createConfirmationObject(values);
    }

    return {};
  };

  const onSubmit = (
    values: ReturnType<typeof buildInitialValues>,
    setStatus: (status?: formikStatus) => void,
    setSubmitting: (isSubmitting: boolean) => void,
    setErrors: (errors: FormikErrors<ReturnType<typeof buildInitialValues>>) => void,
  ) => {
    checkoutOrder(createOrderObject(values), orderState).then(res => {
      updateFormikStatus(res, setStatus, setSubmitting, setErrors);

      if (res.status === Status.Ok) {
        if (res.payload.completedAt) {
          purchase(
            {
              transaction_id: order.number,
              affiliation: 'Printivity',
              revenue: order.total,
              tax: order.taxTotal,
              shipping: order.shippingTotal,
              discount: order.promotionTotal,
              currency: 'USD',
            },
            order.lineItems.map(elem => ({
              item_name: elem.product.name,
              item_id: elem.product.slug,
              price: parseFloat(elem.total) / elem.quantity,
              quantity: elem.quantity,
            })),
          ).then(() => {
            onCreateOrder(res.payload);
          });
        } else {
          setOrder(dispatch, res.payload);
          handleNextStep(res.payload.state);
        }
      } else if (res.status === Status.ClientError) {
        setOrder(dispatch, res.payload.order);
        handleNextStep(res.payload.order.state);
        updateRiskiness(dispatch, res.payload.isRisky);

        if (!!recaptchaRef?.current && order.state === 'confirm') {
          // @ts-ignore
          recaptchaRef.current.reset();
          setRecaptchaResponse(null);
        }
      }
    });
  };

  const getValidationSchema = () => {
    if (currentStep === CheckoutStep.Address) {
      return shippingAddressFormSchema;
    }
    if (currentStep === CheckoutStep.Delivery) {
      return null;
    }
    if (currentStep === CheckoutStep.Payment) {
      if (order.orderTotalAfterStoreCredit > 0) {
        return paymentStepSchemaCreditCard;
      }
      return paymentStepSchemaStoreCredit;
    }
    if (currentStep === CheckoutStep.Confirm) {
      return reviewOrderFormSchema;
    }
    return null;
  };

  return (
    <Formik
      initialValues={buildInitialValues()}
      onSubmit={(values, { setStatus, setSubmitting, setErrors }) => {
        onSubmit(values, setStatus, setSubmitting, setErrors);
      }}
      validationSchema={getValidationSchema()}
    >
      {formikProps => (
        <div className="mt -8  mx-auto max-w-screen-xl">
          <div data-cy="checkoutPageTitle" className="heading-bold-md ml-3 mt-10 md:!ml-3 lg:!ml-16">
            Checkout
          </div>
          <div className="mt-8 flex justify-center">
            <div className="rounded-lg bg-white p-8 shadow-lg md:w-[800px] -md:mx-3 -md:w-full -md:!p-2">
              <Grid noPadding>
                <Grid.Row className="!m-0 !p-0">
                  <div className="!m-0 w-full !p-0">
                    <Form>
                      <ShippingAddressStep
                        shipment={shipment}
                        order={order}
                        addresses={addresses}
                        newAddress={newShipAddress}
                        setNewAddress={setNewShipAddress}
                      />
                      <DeliveryStep />
                      <PaymentStep<PaymentStepFormType>
                        addresses={addresses}
                        newAddress={newBillAddress}
                        setNewAddress={setNewBillAddress}
                        newPayment={formikProps.values.newPayment}
                        setNewPayment={value => formikProps.setFieldValue('newPayment', value)}
                      />
                      <ReviewOrderStep
                        setRecaptchaResponse={setRecaptchaResponse}
                        recaptchaRef={recaptchaRef}
                      />
                    </Form>
                  </div>
                </Grid.Row>
              </Grid>
            </div>
            <div className="lg:ml-6 -lg:hidden">
              <div className="sticky top-14 flex flex-col">
                <PurchaseSummary
                  showPromoCodeField={showPromoCodeField}
                  showPromo={showPromo}
                  disablePromoCodeForm={
                    currentStep === CheckoutStep.Confirm || currentStep === CheckoutStep.Payment
                  }
                  submitPromoCode={() => {
                    applyPromoCode(dispatch, appliedCode);
                  }}
                  removePromoCode={() => {
                    removePromoCode(dispatch, appliedCode || order.promotions[0].promoCode);
                  }}
                  appliedCode={appliedCode}
                  promotions={order.promotions}
                  codeInputHandler={(value: string) => setAppliedCode(value)}
                  buttonText="Almost There"
                  checkoutDisabled
                  errorText={formikProps.status ? formikProps.status.title : null}
                  hideButton={order.state === 'confirm'}
                />
                <NeedHelpSection />
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
export default Steps;
