import * as React from 'react';
import { Field, useFormikContext } from 'formik';
import { Label } from 'styleguide/components/forms';
import { Address } from 'bundles/App/pages/Account/Addresses/types';
import UserContext from 'contexts/UserContextContainer/UserContext';
import Button from 'styleguide/components/Button/Button';
import OrderContext from 'contexts/OrderContext/OrderContext';
import PaymentStepDone from './PaymentStepDone';
import Checkbox from 'styleguide/components/Formik/Checkbox/Checkbox';
import { formatMoney } from 'utils/money';
import AddressBook from 'app/styleguide/components/Formik/AddressBook/AddressBook';
import {
  ExistingCardFormType,
  ExistingCreditCardForm,
} from 'app/styleguide/components/Formik/ExistingCreditCardForm/ExistingCreditCardForm';

interface Props {
  addresses: Address[];
  newAddress: boolean;
  setNewAddress: (newAddress: boolean) => void;
  newPayment: boolean;
  setNewPayment: (newPayment: boolean) => void;
}

export type PaymentStepFormType = {
  useBillingAsShipping: boolean;
  billAddressId: number;
  saveNewBillingAddress: boolean;
} & ExistingCardFormType;

const PaymentStepEdit = <FormikPropsType extends PaymentStepFormType>({
  addresses,
  newAddress,
  setNewAddress,
  newPayment,
  setNewPayment,
}: Props) => {
  const formikProps = useFormikContext<FormikPropsType>();
  const { currentUser } = React.useContext(UserContext);
  const { order } = React.useContext(OrderContext);

  return (
    <>
      {!!order.totalAvailableStoreCredit && (
        <>
          <div className="mb-5">Available store credits: {formatMoney(order.totalAvailableStoreCredit)}</div>
          {order.orderTotalAfterStoreCredit === 0.0 && (
            <div>No payment necessary - store credits will cover your order total!</div>
          )}
        </>
      )}
      {order.orderTotalAfterStoreCredit > 0 &&
        (order.payments.filter(p => p.state === 'checkout').length && !newPayment ? (
          <div className="p-0 md:mt-16">
            <div className="flex items-center justify-between">
              <PaymentStepDone displayAddress={false} />
              <div className="paragraph-mobile flex items-center text-gray-500">or</div>
              <Button
                size="md"
                className="flex items-center rounded-lg !border !border-dashed !border-gray-300 !px-6 !py-2"
                color="dark"
                outline
                onClick={() => setNewPayment(true)}
                type="button"
              >
                <p className="mr-[5px] text-2xl text-blue">+</p>
                <p className="ml-1 text-sm">New Payment</p>
              </Button>
            </div>
          </div>
        ) : (
          <ExistingCreditCardForm formikProps={formikProps} creditCardFormPrefix="creditCardAttributes" />
        ))}
      <div className="mb-6 flex items-baseline justify-start space-x-2">
        <Field
          data-cy="useBillingAsShipping"
          name="useBillingAsShipping"
          component={Checkbox}
          size="sm"
          className=""
        />
        <Label placement="left">Billing address is the same as shipping address</Label>
      </div>
      {!formikProps.values.useBillingAsShipping && (
        <AddressBook
          addressType="bill"
          address={order.billingAddress}
          addresses={addresses}
          newAddress={newAddress}
          setNewAddress={setNewAddress}
          titleOnTheLeft
          addNewAddressToBookCheckBox={!!currentUser}
          addressFormProps={{
            zipWarning: false,
            checkoutMode: true,
            international: true,
            className: '!mt-0',
          }}
        />
      )}
      <Button
        dataCy="paymentStepBtn"
        disabled={formikProps.isSubmitting}
        className="w-full !px-0 !font-hvMedium !text-base"
        type="submit"
        color="orange"
      >
        Review My Order
      </Button>
    </>
  );
};
export default PaymentStepEdit;
